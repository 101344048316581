import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#ededed",
    position: "",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
  subFooter:{
    flexGrow: 1,
    backgroundColor: "#e0e0e0",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    minHeight: theme.spacing(5),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  footerText: {
    color: "black"
  },
  title: {
    color: "black"
  },
  footerBox: {
    paddingLeft:theme.spacing(5),
    paddingRight:theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
    },
  },
  logo: {
    width: "100%",
    minWidth: "150px",

    [theme.breakpoints.up('xs')]: {
      maxWidth: "200px",
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: "250px",
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: "300px",
    },



  }
}));

export default useStyles;