import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    margin: "0px !important",

  }
}));

export default useStyles;